<template>
  <div>
    <div class="header-container">
      <div>
        <v-breadcrumbs>
          <v-breadcrumbs-item class="breadcrumbs-hover-default">
            <i class="fal fa-building" style="margin: 0 10px 0 0"></i>
            <span @click="main()">{{ $t("Company.title") }}</span>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item
            class="breadcrumbs-hover"
            v-if="$route.name === 'company.create'"
          >
            <span v-show="true">/</span>
            <span>{{ $t("Company.create") }}</span>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item
            class="breadcrumbs-hover"
            v-if="$route.name === 'company.user.detail'"
          >
            <span v-show="true">/</span>
            <span>{{ $t("Company.userDetail.title") }}</span>
          </v-breadcrumbs-item>

          <v-breadcrumbs-item
            class="breadcrumbs-hover"
            v-if="$route.name === 'company.edit'"
          >
            <span v-show="true">/</span>
            <span>Edit Company</span>
          </v-breadcrumbs-item>
        </v-breadcrumbs>
      </div>
    </div>

    <div class="page-content">
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },

  methods: {
    main() {
      this.$router
        .push({
          name: "company.index",
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
</style>
